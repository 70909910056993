import React from 'react'

function Resume() {
  return (
    <div>
      <iframe src="https://www.google.com/" width="400" height="400"></iframe>
    </div>
  )
}

export default Resume
